/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputMask from 'react-input-mask';
import styles from './input.module.scss';
import Text from '@/components/atoms/Text';
import Icon from '../Icon';

// eslint-disable-next-line no-unused-vars
const Input = forwardRef((props, ref) => {
  const {
    autoCompleteOff,
    br,
    error,
    blurOnSubmit,
    id,
    inLineStyles,
    onChange,
    onClick,
    placeholder,
    startIcon,
    textColor,
    type,
    width,
    title,
    titleColor,
    filled,
    className,
    value,
    disabled,
    name,
    required,
    onFocus,
    onKeyPress,
    mask,
    maskChar,
    label,
    labelColor,
    checked,
    autoFocus,
    enterKeyHint
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const inputStyles = cn({
    [styles.form]: true,
    [className]: !!className,
    [styles.checkbox]: name === 'without_address_number',
    [styles.inputError]: error
  });

  const inputClass = cn({
    [styles.input]: true,
    [styles.brHard]: br === 'hard',
    [styles.startIcon]: startIcon,
    [styles.filled]: filled
  });

  const widthOptions = {
    fullWidth: '100%',
    none: 'auto',
    sm: '25%',
    md: '50%',
    lg: '75%'
  };
  useEffect(() => {
    if (autoFocus) {
      document.getElementById(id)?.childNodes.forEach((c) => {
        if (c.tagName === 'INPUT') {
          c.focus();
        }
      });
      setTimeout(() => {
        document
          .getElementById(id)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }, []);

  const handleKeyPress = (event) => {
    if (event?.key === 'Enter' && value) {
      if (blurOnSubmit) event.target.blur();
    }
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  return (
    <fieldset
      id={id}
      className={inputStyles}
    >
      {title && (
        <Text
          className={styles.title}
          textColor={titleColor}
          textSize="s"
          weight="light"
        >
          {title}
          {required ? ' *' : ''}
        </Text>
      )}
      {startIcon}
      <InputMask
        enterKeyHint={enterKeyHint || ''}
        autoFocus={autoFocus}
        mask={mask}
        maskChar={maskChar}
        onChange={onChange}
        onFocus={onFocus}
        name={name}
        type={type === 'password' && showPassword ? 'text' : type}
        value={value}
        ref={ref}
        inputRef={ref}
        onClick={onClick}
        onKeyPress={handleKeyPress}
        style={{
          ...inLineStyles,
          width:
            name !== 'without_address_number' ? `${widthOptions[width]}` : '',
          color: error ? '#d9585e' : textColor
        }}
        placeholder={placeholder}
        className={inputClass}
        autoComplete={autoCompleteOff ? 'off' : 'on'}
        disabled={disabled}
        required={required}
        checked={checked}
        tabIndex={0}
      />
      {label && (
        <Text
          forInput="ja"
          textColor={labelColor}
          textSize="xs"
        >
          {label}
        </Text>
      )}
      {error && <div className={styles.errorMessage}>{error}</div>}
      {type === 'password' && (
        <div onClick={() => setShowPassword((prev) => !prev)}>
          <div className={styles.passwordIcon}>
            <Icon type={showPassword ? 'showPassword' : 'hidePassword'} />
          </div>
        </div>
      )}
    </fieldset>
  );
});
Input.propTypes = {
  id: PropTypes.string.isRequired,
  /**
   * Ésta prop cambia el tamaño del input a medidas estándars:
   */
  width: PropTypes.oneOf(['none', 'sm', 'md', 'lg', 'fullWidth']),
  br: PropTypes.oneOf(['soft', 'hard']),
  error: PropTypes.string,
  type: PropTypes.oneOf([
    'email',
    'password',
    'text',
    'number',
    'tel',
    'checkbox'
  ]),
  textColor: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  /**
   * Se renderiza un ícono dentro del botón ubicado a la izquierda:
   */
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  autoCompleteOff: PropTypes.bool,
  inLineStyles: PropTypes.object,
  minLength: PropTypes.shape({
    value: PropTypes.number,
    message: PropTypes.string
  }),
  maxLength: PropTypes.shape({
    value: PropTypes.number,
    message: PropTypes.string
  }),
  title: PropTypes.string,
  titleColor: PropTypes.string,
  filled: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  checked: PropTypes.bool,
  autoFocus: PropTypes.bool,
  enterKeyHint: PropTypes.string,
  blurOnSubmit: PropTypes.bool
};

Input.defaultProps = {
  autoCompleteOff: false,
  br: 'soft',
  error: '',
  titleColor: 'var(--color-white-light)',
  inLineStyles: {},
  maxLength: {},
  minLength: {},
  onChange: () => {},
  onClick: () => {},
  onKeyPress: () => {},
  filled: false,
  placeholder: '',
  startIcon: null,
  textColor: 'var(--color-white-light)',
  value: '',
  title: '',
  type: 'text',
  width: 'fullWidth',
  className: {},
  disabled: false,
  name: '',
  required: false,
  onFocus: () => {},
  mask: '',
  maskChar: '',
  label: null,
  labelColor: 'var(--color-white-light)',
  checked: false,
  autoFocus: false,
  enterKeyHint: '',
  blurOnSubmit: true
};

Input.displayName = 'Input';

export default Input;
